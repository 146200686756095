// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/valuesSection.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/valuesSection.tsx");
  import.meta.hot.lastModified = "1723971484078.9917";
}
// REMIX HMR END

import values from "~/data/values";
import ValuesCard from "./valuesCard";
const ValuesSection = () => {
  return <>
      <div className="section values-section">
        <h2 className="heading-text urdu">اردو, ثقافت اور اقدار</h2>
        <div className="values-container">
          {values.map(({
          heading,
          image
        }, index) => <ValuesCard heading={heading} image={image} key={index} />)}
        </div>
      </div>
    </>;
};
_c = ValuesSection;
export default ValuesSection;
var _c;
$RefreshReg$(_c, "ValuesSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;