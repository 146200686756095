// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/homeHero.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/homeHero.tsx");
  import.meta.hot.lastModified = "1726302806761.8733";
}
// REMIX HMR END

import banner from "~/images/palce.png";
import CTA from "./ctaBtn";
import ImageTextComponent from "./imageTextComponent";
const HomeHero = () => {
  return <>
      <ImageTextComponent image={banner} heading={"Explore, Learn and Engage"} detail={"Soft, entertaining and educational content thoughtfully curated for our children"} component={<CTA link="/signup" text="Explore Now" />} reverse={false} />
    </>;
};
_c = HomeHero;
export default HomeHero;
var _c;
$RefreshReg$(_c, "HomeHero");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;