// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/data/showData.js"
);
import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

import babatoto from "../images/babatoto.png";
import hassanhasna from "../images/hassanhasna.jpg";
import bee from "../images/bee.png";

const showData = [
  {
    image: babatoto,
    showId: "BabaToto",
    showTitle: "Baba Toto",
  },
  {
    image: hassanhasna,
    showId: "HassanAndHasna",
    showTitle: "Hassan and Hasna",
  },
  {
    image: bee,
    showId: "MelodiesOfCreation",
    showTitle: "Melodies of Creation",
  },
];

export default showData;
