// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/homeClosing.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/homeClosing.tsx");
  import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

import banner from "~/images/lori.jpg";
import CTA from "./ctaBtn";
const HomeClosing = () => {
  return <>
      <div className="home-hero">
        <div className="home-hero-bg">
          <img src={banner} alt="" />
          <div className="layer"></div>
        </div>
        <div className="home-hero-content">
          <h2 className="home-hero-heading-text white urdu">
            !تربیت کا آغاز سلام کے ساتھ
          </h2>
          <h2 className="home-hero-heading-text white">
            Upbringing begins with Salam!
          </h2>

          <CTA link="/signup" text="Explore Now" />
        </div>
      </div>
    </>;
};
_c = HomeClosing;
export default HomeClosing;
var _c;
$RefreshReg$(_c, "HomeClosing");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;