// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/valuesCard.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/valuesCard.tsx");
  import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

const ValuesCard = ({
  image,
  heading
}) => {
  return <div className="values-card">
      <img src={image} className="value-image"></img>
      <div className="value-text">{heading}</div>
    </div>;
};
_c = ValuesCard;
export default ValuesCard;
var _c;
$RefreshReg$(_c, "ValuesCard");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;