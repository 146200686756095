// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/data/faq.js"
);
import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

import image1 from "../images/web new 1.png";
import ageGroupImage from "../images/agegroup.png";
import hassan from "../images/bg 2.png";
import sitting from "../images/sitting.png";
import hasna from "../images/bg 4.png";
const faqItems = [
  {
    title: "What is Salam Kids TV?",
    content:
      "Salam Kids TV is a digital streaming platform dedicated to providing culturally rich and age-appropriate content for Urdu-understanding children. Our platform offers a blend of educational programs, animated series, and entertainment, carefully curated for a wholesome digital experience.",
    image: image1,
  },
  {
    title: "What Age Group is Salam Kids TV Targeting?",
    content:
      "Salam Kids TV is designed for children in the age range of [2 to12]. Our content is tailored to meet the developmental needs and interests of this specific age group",
    image: ageGroupImage,
  },
  {
    title: "What Type of Content Will Salam Kids TV Offer?",
    content:
      "Salam Kids TV is dedicated to providing a diverse range of content for children, including animated series, educational programs, cartoons, and more. Our content is curated to align with cultural values and provide a safe and enriching experience for young viewers",
    image: hasna,
  },
  {
    title: "Can I contribute content to Salam Kids TV?",
    content:
      "Yes! Salam Kids TV values community engagement. Join our community, and if eligible, you can contribute to our user-generated content.",
    image: hassan,
  },
  {
    title: "What Will be the Cost of Salam Kids TV Subscription?",
    content:
      "We're excited to announce that a portion of our content will be available for free, allowing customers to enjoy a sneak peek of our engaging offerings. Stay tuned for more information on how to sign up for the free trial",
    image: sitting,
  },
];

export default faqItems;
