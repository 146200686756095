// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/socialMediaGrid.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/socialMediaGrid.tsx");
  import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

import { Link } from "@remix-run/react";
import React from "react";
const SocialMediaIcons = ({
  social_links
}) => {
  return <div className="social-media-grid-wrapper">
      <div className="social-media-grid">
        {social_links.map((image, index) => <div className="social-icon-container" key={index}>
            <Link to={image.link}>
              {" "}
              <img className="social-icon" src={image.icon} alt={image.name} loading="lazy" />
            </Link>
          </div>)}
      </div>
    </div>;
};
_c = SocialMediaIcons;
export default SocialMediaIcons;
var _c;
$RefreshReg$(_c, "SocialMediaIcons");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;