// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/data/values.js"
);
import.meta.hot.lastModified = "1716050307746.1453";
}
// REMIX HMR END

import hassan from "../images/Hassan.png";
import culture from "../images/culture.png";
import hasna2 from "../images/hasna2.png";

const values = [
  {
    heading: "Urdu Content",
    image: hassan,
  },
  {
    heading: "Culturally Alligned",
    image: culture,
  },
  {
    heading: "Values Driven",
    image: hasna2,
  },
];

export default values;
