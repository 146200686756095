// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/faq.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/faq.tsx");
  import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

import faqItems from "~/data/faq";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
const Faq = () => {
  _s();
  const [activeIndex, setActiveIndex] = useState(null);
  const handleToggle = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return <>
      <div className="faq-container">
        {faqItems.map((item, index) => <div key={index} className={`faq-item ${activeIndex === index ? "active" : ""}`} onClick={() => handleToggle(index)}>
            <div className="question-container">
              <div className="question">{item.title}</div>
              <div className="dropdown-icon">
                <FontAwesomeIcon icon={activeIndex === index ? faChevronUp : faChevronDown} />
              </div>
            </div>
            {activeIndex === index && <div className="n">{item.content}</div>}
          </div>)}
      </div>
    </>;
};
_s(Faq, "E8kOn+IkK/htiBGEqJWkEvOqULU=");
_c = Faq;
export default Faq;
var _c;
$RefreshReg$(_c, "Faq");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;