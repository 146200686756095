// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/showCard.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/showCard.tsx");
  import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

import { Link } from "@remix-run/react";
const ShowCard = ({
  image,
  showId,
  showTitle
}) => {
  return <>
      <Link to={`/aboutShow?showId=${showId}`} className="show-link">
        <img className="show-card-image" src={image} alt="" />
        <div className="show-title-container">{showTitle}</div>
      </Link>
    </>;
};
_c = ShowCard;
export default ShowCard;
var _c;
$RefreshReg$(_c, "ShowCard");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;